import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Sitches from './views/Sitches.vue';
import Teams from './views/Teams.vue';
import Gateways from './views/Gateways.vue';
import Translations from './views/Translations.vue';
import SitchDevices from './views/SitchDevices.vue';
import Affiliates from './views/Affiliates.vue';
import Actions from './views/Actions.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'SitchDevices',
    component: SitchDevices,
  },
  {
    path: '/Affiliates',
    name: 'Affiliates',
    component: Affiliates,
  },
  {
    path: '/Sitches',
    name: 'Sitches',
    component: Sitches,
  },
  {
    path: '/Teams',
    name: 'Teams',
    component: Teams,
  },
  {
    path: '/Gateways',
    name: 'Gateways',
    component: Gateways,
  },
  {
    path: '/Translations',
    name: 'Translations',
    component: Translations,
  },
  {
    path: '/Actions',
    name: 'Actions',
    component: Actions,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
