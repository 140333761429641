import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faBars, faExclamation, faLanguage, faList, faRectangleLandscape, faSignOut, faCartArrowDown, faChevronRight, faChevronLeft, faChevronDown, faCaretDown, faSortUp, faHandshake, faUsers, faDoorOpen } from '@fortawesome/pro-light-svg-icons';
import { DocumentData, DocumentReference, Timestamp } from '@firebase/firestore-types';
import { eCardStyle, eChargeStatuses, eCustomFieldTypes, eDeliveryTypes, eInteractionDeviceTypes, eMenuIconTypes, eModeType, eOrderStatuses, eTaxTypes, eThemes, eTranslationLocaleCode, eWeekdays } from './enums';
import { productionFirebaseCredentials, stagingFirebaseCredentials } from './firebase-credentials';

library.add(faList, faBars, faSignOut, faRectangleLandscape, faLanguage, faExclamation, faCartArrowDown, faChevronRight, faChevronLeft, faChevronDown, faCaretDown, faSortUp, faHandshake, faUsers, faDoorOpen);

Vue.component('fa', FontAwesomeIcon);
Vue.component('font-awesome-icon', FontAwesomeIcon);

// Does this pollute the global namespace? Sure, but I like the pros over the cons.
declare global {
  interface String {
    supplant(o: string[]): string;
  }
  interface ModesMap {
    [modeId: string]: AnyMode;
  }
  interface AlertObject {
    message: string;
  }
  interface StripeAccount {
    stripeUserId: string;
    refreshToken: string;
    livemode: boolean;
    scope: string;
    email: string;
    statementDescriptor: string;
    displayName: string;
    customerFacingBusinessName: string;
    url: string;
    phone: string;
    country: string;
    defaultCurrency: string;
  }
  interface User {
    docId: string;
    photoUrl: string;
    displayName: string;
    email: string;
    modeOrder: string[];
    modeShortCut1: string;
    modeShortCut2: string;
    folderOrder: string[];
    folders: FoldersMap;
    additionalVerifiedEmails: string[];
    storageQuota: number;
    settings: UserSettings;
    webPushTokens: string[];
    dateCreated?: Timestamp;
    dateUpdated?: Timestamp;
    joinedTeamsRefs: DocumentReference<DocumentData>[];
    wasCreatedBy: string;
    permalinks?: {
      [modeId: string]: string;
    };
    genericIdsBoundToThisAccount?: {
      [genericId: string]: LinkedDevice;
    };
    managedAccounts?: {
      [userId: string]: ManagedAccount;
    };
  }
  interface ManagedAccount {
    userId: string;
    displayName: string;
    email: string;
    password: string;
    dateCreated: number;
  }
  interface LinkedDevice {
    genericId: string;
    name: string;
    dateActivated: string;
    interactionDeviceType: eInteractionDeviceTypes;
  }
  interface Team {
    docId: string;
    teamOwnerId: string;
    name: string;
    modeIds: string[];
    modes: TeamMode[];
    whiteListedEmails: string[]; // Emails that have access.
    whiteListedEmailDomains: string[]; // Email domains that have access.
    dateCreated: number;
    dateUpdated: number;
  }
  interface TeamMode {
    docId: string;
    linkId: string;
    name: string;
    type: eModeType;
  }
  interface UserSettings {
    locale: eTranslationLocaleCode;
    appTheme: eThemes;
    showNewPaymentAlert: boolean;
    showNewBookingAlert: boolean;
    showNewCustomFormAlert: boolean;
    showNewContactTracingAlert: boolean;
    showNewMessageAlert: boolean;
    shouldNotificationSoundRepeat: boolean;
    getEmailNotificationsForPayments: boolean;
    getEmailNotificationsForBookings: boolean;
    getEmailNotificationsForCustomForms: boolean;
    getEmailNotificationsForMarketing: boolean;
    getWebPushNotificationsForPayments: boolean;
    getWebPushNotificationsForBookings: boolean;
    getWebPushNotificationsForCustomForms: boolean;
  }
  interface FoldersMap {
    [folderId: string]: Folder;
  }
  interface Folder {
    id: string;
    name: string;
    modeOrder: string[];
    dateCreated: number;
  }
  interface AestheticTheme {
    id: string;
    name: string;
    backgroundColor: string;
    accentColor: string;
    primaryFontName: string;
    headerFontName: string;
    primaryFontVariant: FontVariant;
    headerFontVariant: FontVariant;
    primaryFontScale: number;
    headerFontScale: number;
    uppercaseHeaderFont: boolean;
    cardStyle: eCardStyle;
  }
  interface GenericIdData {
    genericId: string;
    name: string;
    interactionDeviceType: eInteractionDeviceTypes;
    dateActivated: number;
    dateCreated: Timestamp;
  }
  interface PublicUserModeGateway {
    docId: string;
    activeModeId: string; // This will be a sitch.app url if the active mode is from a joined team.
    activeModeIsTeamMode: boolean;
    genericIdsBoundToThisAccount: {
      [genericId: string]: GenericIdData; // Maps generic ids to mode ids. For cases where the user wants a specific Sitch tag to always redirect to a specific Sitch.
    };
    isSitchLinkActivated: boolean;
    hasPermanentPremium: boolean;
    loadNewActiveModesAutomatically: boolean;
    premiumSubscriptionId: string;
    stripeAccountsMap: { [stripeAccountId: string]: StripeAccount } | null;
    themes: {
      [id: string]: AestheticTheme;
    };
    dateCreated?: Timestamp;
    dateUpdated?: Timestamp;
  }
  interface UserPaymentRecord {
    referenceId: string;
    paymentIntentId: string;
    stripeReceiptUrl: string | null;
    isLive: boolean;
    orderBreakdown: OrderItem[];
    platformUserId: string; // User ID on the Sitch platform.
    stripeUserId: string;
    formattedBaseAmount: string;
    formattedDiscountAmount: string;
    formattedTaxAmount: string;
    formattedTipAmount: string;
    formattedTotalAmount: string;
    formattedDeliveryFee: string;
    formattedRefundAmount: string;
    formattedDate: string;
    baseAmount: number;
    discountAmount: number;
    taxAmount: number;
    tipAmount: number;
    totalAmount: number;
    currency: string;
    chargeStatus: eChargeStatuses;
    chargeId: string;
    creditCardBrand: string | null | undefined;
    creditCardLastFour: string | null | undefined;
    refundAmount: number;
    deliveryFee: number;
    deliveryAddress: DetailedAddress | null;
    modeId: string;
    personWhoPaidEmail: string;
    personWhoPaidName: string;
    promoCodes: string[];
    locale: string;
    originalOrderBreakdownChecksum: string;
    retrievedOrderBreakdownChecksum: string;
    errors: string[];
    orderStatus: eOrderStatuses;
    dateCreated: Timestamp;
  }
  interface GlobalPaymentRecord extends UserPaymentRecord {
    displayName: string;
    customerFacingBusinessName: string;
    businessEmail: string;
    businessUrl: string;
    businessPhone: string;
    statementDescriptor: string | null;
    paymentIntent: any; //Stripe.PaymentIntent;
  }
  interface DetailedAddress {
    name?: string;
    phoneNumber?: string;
    formattedAddress: string;
    address: GoogleAddress | null;
    addressLine2: string | null;
    additionalAddressDetails: string;
    latitude: number | null;
    longitude: number | null;
    googlePlaceId: string;
    utcOffset: number;
  }
  interface GoogleAddress {
    administrativeAreaLevel1: string;
    administrativeAreaLevel2: string;
    administrativeAreaLevel3: string;
    administrativeAreaLevel4: string;
    administrativeAreaLevel5: string;
    streetAddress: string;
    intersection: string;
    political: string;
    country: string;
    colloquialArea: string;
    locality: string;
    sublocality: string;
    sublocalityLevel1: string;
    sublocalityLevel2: string;
    sublocalityLevel3: string;
    sublocalityLevel4: string;
    sublocalityLevel5: string;
    neighborhood: string;
    postalCode: string;
    route: string;
    streetNumber: string;
    premise: string;
    subpremise: string;
    plusCode: string;
    floor: string;
  }
  interface Mode {
    docId: string;
    linkId: string;
    type: eModeType | null;
    name: string;
    displayName: string;
    index: number;
    dateUpdated?: Timestamp;
    dateCreated?: Timestamp;
    isForUpdate?: boolean;
    themeId: string;
  }
  interface SiteMode extends Mode, ItemWithImages {
    menuIconType: eMenuIconTypes;
    emojiIcons: string[];
    landingPageModeId: string;
    sitePageModeIds: string[]; // Other modes to act as pages for the site. Mode ids.
  }
  interface GroupMode extends Mode {
    groupModeIds: string[];
  }
  interface UrlRedirectMode extends Mode {
    redirectUrl: string;
    putInIframe: boolean;
  }
  interface SubmissionMode {
    preSubmissionCustomFormModeId: string;
    postSubmissionCustomFormModeId: string;
  }
  interface ShopMode extends StripeEnabledMode, SubmissionMode {
    checkoutSuccessMessage: string;
    requiresDeliveryAddress: boolean;
    flatDeliveryFee: number;
    hasDeliveryTaxRate: boolean;
    deliveryTaxRate: number;
    shopItemList: ShopItem[];
    preCheckoutOrderBreakdown: OrderItem[];
    hasPromoCodes: boolean;
    allowSpecialRequests: boolean;
    categories: Category[];
    allowedCountriesForDelivery: string[];
    hasMaxOrderDistance: boolean;
    maxOrderDistance: number;
    locationAddress: DetailedAddress;
    taxType: eTaxTypes;
    computeTaxRateBasedOnLocation: boolean;
    locationsToComputeTaxRateFor: {
      [taxLocationCode: string]: number; // number is the rate charged.
    };
    deliveryType: eDeliveryTypes; // local or shipping
  }
  interface BusinessPaymentsMode extends StripeEnabledMode, SubmissionMode {
    amount: number;
  }
  interface StripeEnabledMode extends Mode {
    message: string;
    currency: string;
    stripeAccountId: string;
    tipOptionsEnabled: boolean;
    taxRate: number;
    requireName: boolean;
    // Shop only properties:
    computeTaxRateBasedOnLocation?: boolean;
    locationsToComputeTaxRateFor?: {
      [taxLocationCode: string]: number; // number is the rate charged.
    };
    requiresDeliveryAddress?: boolean;
    flatDeliveryFee?: number;
    hasDeliveryTaxRate?: boolean;
    deliveryTaxRate?: number;
  }
  interface PersonalPaymentsMode extends Mode {
    amount: number;
    currency: string;
    bitcoinAddress: string;
    bitcoinEnabled: boolean;
    interacEnabled: boolean;
    interacEmail: string;
    interacPhoneNumber: string;
    interacPassword: string;
    paypalEnabled: boolean;
    paypalMeUrl: string;
    venmoEnabled: boolean;
    venmoUrl: string;
    cashAppEnabled: boolean;
    cashAppUrl: string;
  }
  interface FilesMode extends Mode {
    message: string;
    hideIcons: boolean;
    files: StorageFile[];
  }
  interface LinkListMode extends Mode {
    message: string;
    links: Link[];
  }
  interface WifiMode extends Mode {
    ssid: string;
    wifiEncryption: string;
    wifiPassword: string;
  }
  interface GalleryMode extends Mode {
    message: string;
    galleryItems: GalleryItem[];
    galleryItemNamesEnabled: boolean;
  }
  interface BlogMode extends Mode {
    message: string;
    postReferences: BlogPostReference[];
  }
  interface BlogPostContent {
    content: string;
  }
  interface AccordionMode extends Mode {
    message: string;
    sections: AccordionSection[];
  }
  interface ProfileMode extends Mode, ItemWithImages {
    title: string;
    email: string;
    phone: string;
    phoneWork: string;
    website: string;
    ctaButtonLabel: string;
    ctaButtonUrl: string;
    description: string;
    showLabelsForSocials: boolean;
    locationAddress: DetailedAddress;
    showAddAsContactPrompt: boolean;
    // Contains additional data that would be too tedious to list here. Every social option has a url link and an isVisisble flag.
  }
  interface HtmlMode extends Mode {
    html: string;
    js: string;
    css: string;
    minHeight: string;
    maxWidth: string;
  }
  interface ChessMode extends Mode {
    playerTimeLimit: number;
    allowShowThreatsOption: boolean;
  }
  interface ArticleMode extends Mode, ItemWithImages {
    text: string;
  }
  interface EventsMode extends Mode {
    message: string;
    events: SitchEvent[];
  }
  interface ContactTracingMode extends Mode {
    message: string;
    requireName: boolean;
    requireEmail: boolean;
    requirePhoneNumber: boolean;
    requireId: boolean;
    requireScreeningQuestionnaire: boolean;
  }
  interface CustomField {
    id: string;
    name: string;
    type: eCustomFieldTypes;
    description: string;
    choices: string[];
    isRequired: boolean;
  }
  interface EmailableMode extends Mode {
    emailForReceivingNotifications: string;
  }
  interface CustomFormMode extends EmailableMode {
    message: string;
    requireName: boolean;
    requireEmail: boolean;
    requirePhoneNumber: boolean;
    customFields: CustomField[];
  }
  interface ChatMode extends Mode {
    message: string;
    qrMessage: string;
    hasAutoTranslate: boolean;
    maximumParticipants: number;
  }
  interface ReturnToMode extends Mode {
    message: string;
    email: string;
    phone: string;
    phoneWork: string;
    locationAddress: DetailedAddress;
  }
  interface BookingMode extends EmailableMode, SubmissionMode {
    message: string;
    organizationName: string;
    availabilityTimeSlots: TimeSlot[];
    maxBookingsPerDay: number | null;
    bufferTimeBeforeInMinutes: number; // Minimum time before events
    bufferTimeAfterInMinutes: number; // Minimum time after events
    minimumSchedulingNoticeInMinutes: number; // Prevent events less than n minutes away.
    maximumSchedulingNoticeInDays: number; // Prevent events more than n days away.
    timeSteps: 15 | 30 | 60; // In minutes.
    maxSimultaneousBookingsForSameSlot: number;
    durationPerBookingHours: number;
    durationPerBookingMinutes: number;
    closureDates: string[];
    locationAddress: DetailedAddress;
  }
  type AnyMode = CustomFormMode | ContactTracingMode | ProfileMode | WifiMode | FilesMode | PersonalPaymentsMode | BusinessPaymentsMode | UrlRedirectMode | SiteMode | HtmlMode | ShopMode | ArticleMode | EventsMode | BookingMode | GalleryMode;
  interface ModeItem {
    id: string;
  }
  interface Category extends ModeItem {
    name: string;
    shopItemIds: string[];
    isHidden: boolean;
  }
  interface PromoCode extends ModeItem {
    code: string;
    discountPercentage: number;
  }
  interface Link extends ItemWithImages, ModeItem {
    name: string;
    url: string;
    description?: string;
  }
  interface TimeSlot extends ModeItem {
    timeOfDayStart: string;
    timeOfDayEnd: string;
    daysOfWeek: eWeekdays[];
  }
  interface SitchEvent extends ItemWithImages, ModeItem {
    name: string;
    eventDate: string;
    startTime: string;
    durationHours: number;
    durationMinutes: number;
    url: string;
    description: string;
    locationAddress: DetailedAddress;
  }
  interface AccordionSection extends ModeItem {
    name: string;
    text: string;
  }
  interface GalleryItem extends ItemWithImages, ModeItem {
    name: string;
    url?: string;
    description?: string;
  }
  interface BlogPostReference extends ItemWithImages, ModeItem {
    name: string;
    subtitle: string;
    author: string;
    goLiveDate: string;
    goLiveTime: string;
    utcOffset: number;
  }
  interface StorageFile extends ModeItem {
    base64ImagePreview?: string;
    mimeType: string;
    fileName: string;
    description: string;
    storagePath: string;
    downloadUrl: string;
  }
  interface CompressedFile {
    id: string;
    mimeType: string;
    fileName: string;
    description: string;
    file: File;
    base64ImagePreview?: string;
  }
  interface ShopItem extends ItemWithImages, ModeItem {
    name: string;
    isHidden: boolean;
    isSoldOut: boolean;
    price: number;
    description: string;
    isAgeGated: boolean;
    minimumAge: number;
    modifierGroups: ModifierGroup[];
    minimumQuantity: number;
    hasUniqueTaxRate: boolean;
    uniqueTaxRate: number;
    hasStock: boolean;
    stock: number;
  }
  interface ModifierGroup {
    id: string;
    name: string;
    description: string;
    isHidden: boolean;
    isSoldOut: boolean;
    modifiers: Modifier[];
    numberOfSelections: number;
    mustPickExactAmount: boolean;
    canSelectMultipleOfSameModifier: boolean;
    userOptedToSelectLessThanMax?: boolean; // Delete this before saving, only used to customer checkout.
  }
  interface Modifier {
    id: string;
    name: string;
    isHidden: boolean;
    isSoldOut: boolean;
    amountChange: number;
    requiresCustomerInput: boolean;
    customerInputLabel: string;
    hasStock: boolean;
    stock: number;
    // Only for store fronts, not needed on forms.
    quantitySelected: number;
    customerInput: string;
  }
  interface OrderItem {
    id: string;
    name: string;
    shopItemId: string;
    specialRequest: string;
    quantity: number;
    baseAmount: number; // ShopItem price.
    amountWithModifiers: number; // With OrderedModifier quantities factored in.
    totalAmount: number; // With OrderItem quantity factored in.
    totalAmountString: string;
    totalTaxAmount: number;
    totalAmountWithTax: number;
    selectedModifiers: SelectedModifiers;
    hasUniqueTaxRate: boolean;
    uniqueTaxRate: number;
    progenitorMinimumQuantity: number;
  }
  interface SelectedModifiers {
    [key: string]: OrderedModifier[];
  }
  interface OrderedModifier {
    name: string;
    modifierId: string;
    amountChange: number;
    customerInput: string;
    quantity: number;
  }
  interface ImageSizes {
    small?: number;
    large?: number;
  }
  interface ItemWithImages {
    images: ModeImage[];
  }
  interface ModeImage {
    id: string;
    toBeUploaded?: boolean; // Font end UI flag for deciding what preview to show. Delete this before uploading.
    markedForDeletion?: boolean; // A flag to mark the image to be deleted in the handleImages function. This flag overrides all other fields in determining an images fate.
    smallImageBase64Preview?: string; // The image base64 encoded to the preview the image before being uploaded
    largeImageBase64Preview?: string; // The image base64 encoded to the preview the image before being uploaded
    smallImageUrl?: string; // The url used to show an uploaded image on the frontend.
    smallImageStoragePath?: string; // Location of the image in the firestore bucket once it has been uploaded. For future image deletion if ncessary
    smallImageFileName?: string; // Randomly generated ID. Does not use the original filename of the uploaded image file.
    smallCompressedImage?: File | null; // Raw file data for the image being uploaded after dimension compression. Delete this before uploading.
    largeImageUrl?: string; // The url used to show an uploaded image on the frontend.
    largeImageStoragePath?: string; // Location of the image in the firestore bucket once it has been uploaded. For future image deletion if ncessary
    largeImageFileName?: string; // Randomly generated ID. Does not use the original filename of the uploaded image file.
    largeCompressedImage?: File | null; // Raw file data for the image being uploaded after dimension compression. Delete this before uploading.
  }
  interface CompressedFileObject {
    compressedFile: File;
    base64data: string;
  }
  interface TextValue {
    text: string;
    value: any;
  }
  interface Font {
    name: string;
    variants: string[];
  }

  type TableHeaders = { text: string; value?: string; sortable?: boolean; align?: string }[];

  interface Submission {
    docId: string;
    dateCreated: Timestamp;
  }
  interface BookingSlot extends Submission {
    startDateInMilliseconds: number;
    endDateInMilliseconds: number;
    asString: string;
    asStringWithDate: string;
    asStringInternational: string;
    asStringWithDateInternational: string;
    organizationName: string;
    durationMinutes: number;
    bookerDetailsArray: BookerDetails[];
    googleLink?: string;
    outlookLink?: string;
    office365Link?: string;
    icsLink?: string;
    dateUpdated?: Timestamp;
    locationAddress: DetailedAddress;
  }
  interface BookerDetails {
    name: string;
    email: string;
    phoneNumber: string;
    securityKey: string;
    customFields: {
      [id: string]: CustomFieldData;
    };
    locale: string;
    dateCreated: number;
  }
  interface ContactTracingSubmission extends Submission {
    name: string;
    email: string;
    phoneNumber: string;
    id: string; // Optional user input field for data collection ID. Not object metadata.
    questionnairePassed: null | boolean;
  }
  interface CustomFormSubmission extends Submission {
    name: string;
    email: string;
    phoneNumber: string;
    userId: string;
    customFields: {
      [id: string]: CustomFieldData;
    };
  }
  interface CustomFieldData {
    name: string;
    type: string;
    value: string | boolean | number | Date | undefined;
  }
  interface SubmissionPageData extends SubmissionPageMixinData {
    headers: TableHeaders;
  }
  interface SubmissionPageMixinData {
    currSelectedMode: null | Mode; // Populated by the select dropdown on each submission review page.
    submissionArray: Submission[];
    dateRangeMenu: boolean;
    dateRange: string[];
    periodSpecified: boolean;
    currSubmission: Submission | null;
    mostRecentSubmission: Submission | null;
    showSubmissionDetailsDialog: boolean;
    mostRecentSubmissionDateInMs: number;
    modeWasJustLoaded: boolean;
    watchedCollection: (() => void) | null;
  }
  interface ModeMixinMethods {
    pruneAndCommitDeletionsJustBeforeCreatingCombinedModeData: () => Promise<any[]>;
    postDeletionPreviewArray: (array: { id: string }[]) => any[];
  }
  interface ModeItemFormData {
    onMarkForDeletion: (itemId: string, arrayPropName: string) => void;
    onUnmarkForDeletion: (itemIdToRemove: string) => void;
    itemsToBeRemovedById: string[];
  }
  interface SitchApiSuccessfulResponse {
    successfulResponse: any;
  }
  interface SitchApiErrorResponse {
    errorMessage: string;
    error?: any;
  }
  interface FileUploadsObject {
    [uploadId: string]: number;
  }

  type SitchApiResponse = SitchApiSuccessfulResponse & SitchApiErrorResponse;
  type FontVariant = '100' | '100italic' | '200' | '200italic' | '300' | '300italic' | 'regular' | 'italic' | '500' | '500italic' | '600' | '600italic' | '700' | '700italic' | '800' | '800italic' | '900' | '900italic' | 'bold';
}

Vue.config.productionTip = false;

export const isProductionEnv = process.env.NODE_ENV === 'production' && window.location.hostname.includes('https://sitch-admin-app.web.app.app');
export const apiUrl = isProductionEnv ? 'https://sitch-api-dot-sitch-app.appspot.com' : 'https://sitch-test-api-dot-sitch-app-test-64014.uc.r.appspot.com';
export let currFirebase: firebase.app.App;

if (isProductionEnv) {
  currFirebase = firebase.initializeApp(productionFirebaseCredentials);
} else {
  currFirebase = firebase.initializeApp(stagingFirebaseCredentials);
}

export const currFirestore = currFirebase.firestore();

export const signIn = (email: string, password: string) => {
  return currFirebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then((authUser) => {
      console.log('authUser', authUser.user?.uid);
      store.commit('isLoggedIn', true);
    })
    .catch((error) => {
      alert('Could not sign in. ' + (error.message || error));
    });
};

currFirebase
  .auth()
  .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
  .then(() => {
    return currFirebase.auth().onAuthStateChanged((user) => {
      if (user) {
        store.commit('isLoggedIn', true);
        if (user.uid !== 'LMnlgmyZHOQUEngEPyZByVR5nzO2' && user.uid !== '6mP6YhIGkJdsZMQTRjCRgzlhxsg2') {
          console.log('Not a valid admin user:', user.uid);
          currFirebase.auth().signOut();
        }
      } else {
        store.commit('isLoggedIn', false);
      }
    });
  });

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
