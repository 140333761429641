





















import Vue from 'vue';
import { currFirestore } from '../main';
export default Vue.extend({
  data(): {
    allTeams: any[];
    headers: any[];
  } {
    return {
      headers: [
        {
          text: 'Date Updated',
          value: 'dateUpdated',
          sortable: true,
        },
        {
          text: 'Date Created',
          value: 'dateCreated',
          sortable: true,
        },
        {
          text: 'Name',
          value: 'name',
          sortable: true,
        },
      ],
      allTeams: [],
    };
  },
  methods: {
    getAllTeams() {
      const allTeams: any[] = [];
      const usersCollection = currFirestore.collection('users');
      const promises: any[] = [];
      promises.push(
        usersCollection.get().then((querySnapshot) => {
          querySnapshot.forEach((userDoc) => {
            promises.push(
              usersCollection
                .doc(userDoc.data().docId)
                .collection('teams')
                .get()
                .then((querySnapshot) => {
                  querySnapshot.forEach((teamDoc) => {
                    const team = teamDoc.data() as Team;
                    if (!team.dateCreated) {
                      team.dateCreated = 0;
                    }
                    allTeams.push(team);
                  });
                })
            );
          });
        })
      );
      Promise.all(promises).then(() => {
        this.allTeams = allTeams.sort((a, b) => b.dateCreated - a.dateCreated);
      });
    },
  },
});
