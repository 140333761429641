



























import Vue from 'vue';
import { currFirestore } from '../main';
export default Vue.extend({
  data(): {
    allSitchLinks: any[];
    headers: any[];
  } {
    return {
      headers: [
        {
          text: 'Date Updated',
          value: 'dateUpdated',
          sortable: true,
        },
        {
          text: 'Date Created',
          value: 'dateCreated',
          sortable: true,
        },
        {
          text: 'Name',
          value: 'name',
          sortable: true,
        },
        {
          text: 'Type',
          value: 'type',
          sortable: true,
        },
        { text: 'link', value: 'url', sortable: false, align: 'center' },
      ],
      allSitchLinks: [],
    };
  },
  methods: {
    getAllModes() {
      const allSitchLinks: any[] = [];
      const publicUserModeGatewaysCollection = currFirestore.collection('publicUserModeGateways');
      const promises: any[] = [];
      promises.push(
        publicUserModeGatewaysCollection.get().then((querySnapshot) => {
          querySnapshot.forEach((gatewayDoc) => {
            promises.push(
              publicUserModeGatewaysCollection
                .doc(gatewayDoc.data().docId)
                .collection('modes')
                .get()
                .then((querySnapshot) => {
                  querySnapshot.forEach((modeDoc) => {
                    const mode = modeDoc.data();
                    const dateUpdated = mode.dateUpdated?.seconds;
                    const dateCreated = mode.dateCreated?.seconds;
                    if (mode.linkId) {
                      allSitchLinks.push({ dateUpdated, dateCreated, url: `https://sitch.app/s/${mode.linkId}`, name: mode.name, type: mode.type });
                    } else {
                      allSitchLinks.push({ dateUpdated, dateCreated, url: `https://sitch.app/?u=${gatewayDoc.data().docId}&am=${mode.docId}`, name: mode.name, type: mode.type });
                    }
                  });
                })
            );
          });
        })
      );
      Promise.all(promises).then(() => {
        this.allSitchLinks = allSitchLinks.sort((a, b) => b.date - a.date);
      });
    },
  },
});
