










import Vue from 'vue';
import { currFirestore } from '../main';
import translationMessagesForManagerEn from '../assets/json/translations-for-manager-en';
import translationMessagesForClientEn from '../assets/json/translations-for-client-en';

import translate from 'translate';
import { endpoints, standardApiFetch } from '@/util';
translate.engine = 'google';
translate.key = 'AIzaSyA6cooz0GYvoIUCn4g62Rim6UfEZ_5CO8Q';

export const ALL_LOCALES = [
  { text: 'Afrikaans', value: 'af' },
  { text: 'Albanian', value: 'sq' },
  { text: 'Amharic', value: 'am' },
  { text: 'Arabic', value: 'ar' },
  { text: 'Armenian', value: 'hy' },
  { text: 'Azerbaijani', value: 'az' },
  { text: 'Basque', value: 'eu' },
  { text: 'Belarusian', value: 'be' },
  { text: 'Bengali', value: 'bn' },
  { text: 'Bosnian', value: 'bs' },
  { text: 'Bulgarian', value: 'bg' },
  { text: 'Catalan', value: 'ca' },
  { text: 'Chinese', value: 'zh' },
  { text: 'Corsican', value: 'co' },
  { text: 'Croatian', value: 'hr' },
  { text: 'Czech', value: 'cs' },
  { text: 'Danish', value: 'da' },
  { text: 'Dutch', value: 'nl' },
  { text: 'Esperanto', value: 'eo' },
  { text: 'Estonian', value: 'et' },
  { text: 'Finnish', value: 'fi' },
  { text: 'French', value: 'fr' },
  { text: 'Frisian', value: 'fy' },
  { text: 'Galician', value: 'gl' },
  { text: 'Georgian', value: 'ka' },
  { text: 'German', value: 'de' },
  { text: 'Greek', value: 'el' },
  { text: 'Gujarati', value: 'gu' },
  { text: 'Haitian', value: 'ht' },
  { text: 'Hausa', value: 'ha' },
  { text: 'Hebrew', value: 'he' },
  { text: 'Hindi', value: 'hi' },
  { text: 'Hungarian', value: 'hu' },
  { text: 'Icelandic', value: 'is' },
  { text: 'Igbo', value: 'ig' },
  { text: 'Indonesian', value: 'id' },
  { text: 'Irish', value: 'ga' },
  { text: 'Italian', value: 'it' },
  { text: 'Japanese', value: 'ja' },
  { text: 'Javanese', value: 'jv' },
  { text: 'Kannada', value: 'kn' },
  { text: 'Kazakh', value: 'kk' },
  { text: 'Khmer', value: 'km' },
  { text: 'Kinyarwanda', value: 'rw' },
  { text: 'Korean', value: 'ko' },
  { text: 'Kurdish', value: 'ku' },
  { text: 'Kyrgyz', value: 'ky' },
  { text: 'Lao', value: 'lo' },
  { text: 'Latin', value: 'la' },
  { text: 'Latvian', value: 'lv' },
  { text: 'Lithuanian', value: 'lt' },
  { text: 'Luxembourgish', value: 'lb' },
  { text: 'Macedonian', value: 'mk' },
  { text: 'Malagasy', value: 'mg' },
  { text: 'Malay', value: 'ms' },
  { text: 'Malayalam', value: 'ml' },
  { text: 'Maltese', value: 'mt' },
  { text: 'Maori', value: 'mi' },
  { text: 'Marathi', value: 'mr' },
  { text: 'Mongolian', value: 'mn' },
  { text: 'Burmese', value: 'my' },
  { text: 'Nepali', value: 'ne' },
  { text: 'Norwegian', value: 'no' },
  { text: 'Chichewa', value: 'ny' },
  { text: 'Oriya', value: 'or' },
  { text: 'Pashto', value: 'ps' },
  { text: 'Persian', value: 'fa' },
  { text: 'Polish', value: 'pl' },
  { text: 'Portuguese', value: 'pt' },
  { text: 'Punjabi', value: 'pa' },
  { text: 'Romanian', value: 'ro' },
  { text: 'Russian', value: 'ru' },
  { text: 'Samoan', value: 'sm' },
  { text: 'Scots Gaelic', value: 'gd' },
  { text: 'Serbian', value: 'sr' },
  { text: 'Sesotho', value: 'st' },
  { text: 'Shona', value: 'sn' },
  { text: 'Sindhi', value: 'sd' },
  { text: 'Sinhala', value: 'si' },
  { text: 'Slovak', value: 'sk' },
  { text: 'Slovenian', value: 'sl' },
  { text: 'Somali', value: 'so' },
  { text: 'Spanish', value: 'es' },
  { text: 'Sundanese', value: 'su' },
  { text: 'Swahili', value: 'sw' },
  { text: 'Swedish', value: 'sv' },
  { text: 'Tagalog', value: 'tl' },
  { text: 'Tajik', value: 'tg' },
  { text: 'Tamil', value: 'ta' },
  { text: 'Tatar', value: 'tt' },
  { text: 'Telugu', value: 'te' },
  { text: 'Thai', value: 'th' },
  { text: 'Turkish', value: 'tr' },
  { text: 'Turkmen', value: 'tk' },
  { text: 'Ukrainian', value: 'uk' },
  { text: 'Urdu', value: 'ur' },
  { text: 'Uyghur', value: 'ug' },
  { text: 'Uzbek', value: 'uz' },
  { text: 'Vietnamese', value: 'vi' },
  { text: 'Welsh', value: 'cy' },
  { text: 'Xhosa', value: 'xh' },
  { text: 'Yiddish', value: 'yi' },
  { text: 'Yoruba', value: 'yo' },
  { text: 'Zulu', value: 'zu' },
];

export const TOP_LOCALES = [
  { text: 'Chinese', value: 'zh' },
  { text: 'Spanish', value: 'es' },
  { text: 'Hindi', value: 'hi' },
  { text: 'Arabic', value: 'ar' },
  { text: 'Bengali', value: 'bn' },
  { text: 'Portuguese', value: 'pt' },
  { text: 'Russian', value: 'ru' },
  { text: 'Japanese', value: 'ja' },
  { text: 'Punjabi', value: 'pa' },
  { text: 'Italian', value: 'it' },
  { text: 'German', value: 'de' },
  { text: 'French', value: 'fr' },
];

export const TEST_LOCALES = [
  { text: 'Chinese', value: 'zh' },
  { text: 'French', value: 'fr' },
];

export default Vue.extend({
  name: 'app',
  data(): {

  } {
    return {

    };
  },
  methods: {
    // Server translations
    doTranslations() {
      standardApiFetch(endpoints.doTranslations, {
        password: 'HF85KD9VKL40JVM$$',
        product: 'client',
        localeGroup: 'test',
      }).then((response) => {
        console.log(response.successfulResponse);
      });
    },
    // Local client translations.
    uploadClientTranslations() {
      const newClientTranslations: any = {};

      const promiseArray: Array<Promise<any>> = [];
      Object.values(ALL_LOCALES)
        .map((localeObj) => localeObj.value)
        .forEach((locale, index) => {
          setTimeout(() => {
            Object.keys(translationMessagesForClientEn).forEach((stringId: string) => {
              const promise: Promise<void> = new Promise((resolve, reject) => {
                translate((translationMessagesForClientEn as any)[stringId], {
                  from: 'en',
                  to: locale,
                })
                  .then((translatedText: string) => {
                    resolve();
                    newClientTranslations[locale] = newClientTranslations[locale] || {};
                    newClientTranslations[locale][stringId] = translatedText;
                  })
                  .catch(() => {
                    reject();
                  });
              });
              promiseArray.push(promise);
            });

            Promise.all(promiseArray).then(() => {
              currFirestore
                .collection('clientTranslations')
                .doc(locale)
                .set(newClientTranslations[locale])
                .then(() => {
                  console.log(`Finished uploading client translations ${locale}`);
                });
            });
          }, 2000 * index);
        });
    },
    // Local manager translations.
    uploadManagerTranslations() {
      const newManagerTranslations: any = {};

      const promiseArray: Array<Promise<any>> = [];
      Object.values(ALL_LOCALES)
        .map((localeObj) => localeObj.value)
        .forEach((locale, index) => {
          setTimeout(() => {
            Object.keys(translationMessagesForManagerEn).forEach((stringId: string) => {
              const promise: Promise<void> = new Promise((resolve, reject) => {
                translate((translationMessagesForManagerEn as any)[stringId], {
                  from: 'en',
                  to: locale,
                })
                  .then((translatedText: string) => {
                    resolve();
                    newManagerTranslations[locale] = newManagerTranslations[locale] || {};
                    newManagerTranslations[locale][stringId] = translatedText;
                  })
                  .catch(() => {
                    reject();
                  });
              });
              promiseArray.push(promise);
            });

            Promise.all(promiseArray).then(() => {
              currFirestore
                .collection('managerTranslations')
                .doc(locale)
                .set(newManagerTranslations[locale])
                .then(() => {
                  console.log(`Finished uploading client translations ${locale}`);
                });
            });
          }, 2000 * index);
        });
    },
  },
});
