// Important: These labels are stored in the database verbatim, don't change them!
export enum eInteractionDeviceTypes {
  blackSitchCard = 'blackSitchCard',
  blackSitchBand = 'blackSitchBand',
  blackSitchDot = 'blackSitchDot',
  blackUnisexShirt = 'blackUnisexShirt',
  blackWomensShirt = 'blackWomensShirt',
  whiteCustomLogoSitchCard = 'whiteCustomLogoSitchCard',
  tapeeo = 'tapeeo',
}

export enum eModeType {
  profile = 'profile',
  urlRedirect = 'urlRedirect',
  article = 'article',
  files = 'files',
  personalPayment = 'personalPayment',
  businessPayment = 'businessPayment',
  wifi = 'wifi',
  site = 'site',
  linkList = 'linkList',
  gallery = 'gallery',
  group = 'group',
  shop = 'shop',
  html = 'html',
  events = 'events',
  booking = 'booking',
  contactTracing = 'contactTracing',
  customForm = 'customForm',
  blog = 'blog',
  accordion = 'accordion',
  returnTo = 'returnTo',
  chat = 'chat',
  chess = 'chess',
}

export enum eCardStyle {
  flat = 'flat',
  sharp = 'sharp',
  round = 'round',
}

export enum eFeatureFlagTypes {
  off,
  test,
  prod,
}

export enum ePaymentStatus {
  sitchPremiumdPaymentSucceeded = 'sitchPremiumPaymentSucceeded',
  sitchPremiumdPaymentCancelled = 'sitchPremiumPaymentCancelled',
}

export enum eChargeStatuses {
  completed = 'completed',
  cancelled = 'cancelled',
  authorized = 'authorized',
}

export enum eWeekdays {
  sunday = 'sunday',
  monday = 'monday',
  tuesday = 'tuesday',
  wednesday = 'wednesday',
  thursday = 'thursday',
  friday = 'friday',
  saturday = 'saturday',
}

export enum eCustomFieldTypes {
  email = 'email',
  phoneNumber = 'phoneNumber',
  url = 'url',
  shortText = 'shortText',
  longText = 'longText',
  number = 'number',
  date = 'date',
  time = 'time',
  checkbox = 'checkbox',
  outOf5 = 'outOf5',
  multipleChoice = 'multipleChoice',
}

export enum eOrderStatuses {
  noStatus = 'noStatus',
  onHold = 'onHold',
  accepted = 'accepted',
  rejected = 'rejected',
  completed = 'completed',
  // Delivery specific
  dispatched = 'dispatched',
  delivered = 'delivered',
  // Take out specific
  readyForPickUp = 'readyForPickUp',
  pickedUp = 'pickedUp',
}

export enum eThemes {
  dark = 'dark',
  light = 'light',
}

export enum eTranslationLocaleCode {
  de = 'de',
  es = 'es',
  fr = 'fr',
  zh = 'zh',
  en = 'en',
  notSet = 'notSet',
}

export enum eMenuIconTypes {
  none = 'none',
  standard = 'standard',
  emoji = 'emoji',
}

export enum eSignInErrors {
  databaseError = 'databaseError',
  alreadyActivated = 'alreadyActivated',
  idNotValid = 'idNotValid',
}

export enum eDeliveryTypes {
  local = 'local',
  shipping = 'shipping',
}

export enum eTaxTypes {
  userSpecified = 'userSpecified',
  stripe = 'stripe',
}