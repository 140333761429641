
























import Vue from 'vue';
import { currFirestore } from '../main';
export default Vue.extend({
  data(): {
    allGateways: any[];
    headers: any[];
  } {
    return {
      headers: [
        {
          text: 'Date Updated',
          value: 'dateUpdated',
          sortable: true,
        },
        {
          text: 'Date Created',
          value: 'dateCreated',
          sortable: true,
        },
        {
          text: 'ID',
          value: 'docId',
          sortable: true,
        },
        {
          text: 'Premium Sub ID',
          value: 'premiumSubscriptionId',
          sortable: true,
        },
      ],
      allGateways: [],
    };
  },
  methods: {
    getallGateways() {
      const allGateways: any[] = [];
      const usersCollection = currFirestore.collection('publicUserModeGateways').orderBy('dateCreated', 'desc');
      const promises: any[] = [];
      promises.push(
        usersCollection.get().then((querySnapshot) => {
          querySnapshot.forEach((docSnap) => {
            const currUserModeGateway = docSnap.data();
            const premiumSubscriptionId = currUserModeGateway.premiumSubscriptionId;
            const dateUpdated = currUserModeGateway.dateUpdated?.seconds;
            const dateCreated = currUserModeGateway.dateCreated?.seconds;
            allGateways.push({
              ...currUserModeGateway,
              docId: docSnap.id,
              dateUpdated,
              dateCreated,
            });
            if (premiumSubscriptionId) {
              console.log(currUserModeGateway);
              // standardApiFetch(endpoints.getStripeSubscription, {
              //   premiumSubscriptionId,
              // }).then((response) => {
              //   store.commit('premiumSubData', response.successfulResponse.subscription);
              // });
            }
          });
        })
      );

      Promise.all(promises).then(() => {
        this.allGateways = allGateways.sort((a, b) => b.dateCreated - a.dateCreated);
      });
    },
  },
});
