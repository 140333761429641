

































import Vue from 'vue';
import { currFirestore, isProductionEnv } from '../main';
import firebase from 'firebase/app';
import { endpoints, standardApiFetch } from '../util';
import { eInteractionDeviceTypes } from '@/enums';

export default Vue.extend({
  name: 'app',
  data(): {
    isProductionEnv: boolean;
    subIdToCancel: string;
    promoCode: string;
  } {
    return {
      subIdToCancel: '',
      promoCode: '',
      isProductionEnv,
    };
  },
  methods: {
    addPromoCode() {
      // EU store: 9rffRpIG2fQVEw1PeIrS
      // CA store: Cj2YT8bQD0xd2MZySi1M
      // INT store: Hm9XoV47vCQoN9uBNKWp
      // GB store: JNAA5CNuEXcucgT9sNxt
      // US store: Cj2YT8bQD0xd2MZySi1M
      // Demo store: NdCZbisN3JSv64yZ6Rwr
      const promoCode = this.promoCode.toUpperCase();
      const shopModes = ['pAH8aW3bWSZ0ye6rhZLD', 'JNAA5CNuEXcucgT9sNxt', 'Hm9XoV47vCQoN9uBNKWp', 'Cj2YT8bQD0xd2MZySi1M', '9rffRpIG2fQVEw1PeIrS'];
      // const shopModes = ['NdCZbisN3JSv64yZ6Rwr'];
      const newPromoCodeData: PromoCode = {
        id: this.generateId(),
        code: promoCode,
        discountPercentage: 20,
      };
      shopModes.forEach((shopModeId) => {
        currFirestore.collection('publicUserModeGateways').doc('KvE45eNAmlUhk5y7xJnbAJM8bP02').collection('modes').doc(shopModeId).collection('promoCodes').doc(promoCode).set(newPromoCodeData);
      });
      alert('done');
    },
    onUpdateUserData() {
      currFirestore
        .collection('users')
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            currFirestore.collection('users').doc(doc.data().docId).update({});
          });
        });
    },
    generateId() {
      return currFirestore.collection('This is just to generate IDs').doc().id;
    },
    addDateToShortPermalinks() {
      const col = currFirestore.collection('shortPermalinks');
      col.get().then((querySnapshot) => {
        const batch = currFirestore.batch();
        querySnapshot.forEach((pDoc) => {
          batch.update(pDoc.ref, {
            dateCreated: firebase.firestore.FieldValue.serverTimestamp(),
          });
        });
        batch.commit();
        console.log('done');
      });
    },
    addDateToCustomPermalinks() {
      const col = currFirestore.collection('customPermalinks');
      col.get().then((querySnapshot) => {
        const batch = currFirestore.batch();
        querySnapshot.forEach((pDoc) => {
          batch.update(pDoc.ref, {
            dateCreated: firebase.firestore.FieldValue.serverTimestamp(),
          });
        });
        batch.commit();
        console.log('done');
      });
    },
    seeModeDates() {
      const publicUserModeGatewaysCollection = currFirestore.collection('publicUserModeGateways');
      publicUserModeGatewaysCollection.get().then((querySnapshot) => {
        querySnapshot.forEach((gatewayDoc) => {
          publicUserModeGatewaysCollection
            .doc(gatewayDoc.data().docId)
            .collection('modes')
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((modeDoc) => {
                const mode = modeDoc.data();
                console.log('mode.dateCreated', mode.dateCreated);
                console.log('mode.dateUpdated', mode.dateUpdated);
              });
              console.log('done');
            });
        });
      });
    },
    fixUndefinedModeDates() {
      const publicUserModeGatewaysCollection = currFirestore.collection('publicUserModeGateways');
      publicUserModeGatewaysCollection.get().then((querySnapshot) => {
        querySnapshot.forEach((gatewayDoc) => {
          publicUserModeGatewaysCollection
            .doc(gatewayDoc.data().docId)
            .collection('modes')
            .get()
            .then((querySnapshotInner) => {
              const batch = currFirestore.batch();
              querySnapshotInner.forEach((modeDoc) => {
                const mode = modeDoc.data();
                const update: any = {};

                if (mode.dateUpdated === undefined) {
                  update.dateUpdated = firebase.firestore.FieldValue.serverTimestamp();
                }
                if (mode.dateCreated === undefined) {
                  update.dateCreated = new firebase.firestore.Timestamp(mode.dateUpdated.seconds, mode.dateUpdated.nanoseconds);
                }
                if (Object.keys(update).length) {
                  batch.update(modeDoc.ref, update);
                }
              });
              batch.commit();
            });
        });

        console.log('done');
      });
    },
    fixWeirdModeDates() {
      const publicUserModeGatewaysCollection = currFirestore.collection('publicUserModeGateways');
      publicUserModeGatewaysCollection.get().then((querySnapshot) => {
        querySnapshot.forEach((gatewayDoc) => {
          publicUserModeGatewaysCollection
            .doc(gatewayDoc.data().docId)
            .collection('modes')
            .get()
            .then((querySnapshotInner) => {
              const batch = currFirestore.batch();
              querySnapshotInner.forEach((modeDoc) => {
                const mode = modeDoc.data();
                console.log('mode.dateCreated', mode.dateCreated);
                console.log('mode.dateUpdated', mode.dateUpdated);
                const update: any = {};
                if (mode.dateUpdated._delegate) {
                  const ts = mode.dateUpdated._delegate;
                  update.dateUpdated = new firebase.firestore.Timestamp(ts.seconds, ts.nanoseconds);
                }
                if (mode.dateUpdated.if) {
                  const ts = mode.dateUpdated.if;
                  update.dateUpdated = new firebase.firestore.Timestamp(ts.seconds, ts.nanoseconds);
                }
                if (mode.dateCreated._delegate) {
                  const ts = mode.dateCreated._delegate;
                  update.dateCreated = new firebase.firestore.Timestamp(ts.seconds, ts.nanoseconds);
                }
                if (mode.dateCreated.if) {
                  const ts = mode.dateCreated.if;
                  update.dateCreated = new firebase.firestore.Timestamp(ts.seconds, ts.nanoseconds);
                }
                if (Object.keys(update).length) {
                  batch.update(modeDoc.ref, update);
                }
              });
              batch.commit();
            });
        });

        console.log('done');
      });
    },

    fixUndefinedGatewayDates() {
      const publicUserModeGatewaysCollection = currFirestore.collection('publicUserModeGateways');
      publicUserModeGatewaysCollection.get().then((querySnapshot) => {
        const batch = currFirestore.batch();
        querySnapshot.forEach((modeDoc) => {
          const mode = modeDoc.data();
          const update: any = {};

          if (mode.dateUpdated === undefined) {
            update.dateUpdated = firebase.firestore.FieldValue.serverTimestamp();
          }
          if (mode.dateCreated === undefined) {
            update.dateCreated = new firebase.firestore.Timestamp(mode.dateUpdated.seconds, mode.dateUpdated.nanoseconds);
          }
          if (Object.keys(update).length) {
            batch.update(modeDoc.ref, update);
          }
        });
        batch.commit();

        console.log('done');
      });
    },
    fixWeirdGatewayDates() {
      const publicUserModeGatewaysCollection = currFirestore.collection('publicUserModeGateways');
      publicUserModeGatewaysCollection.get().then((querySnapshot) => {
        const batch = currFirestore.batch();
        querySnapshot.forEach((modeDoc) => {
          const mode = modeDoc.data();
          console.log('mode.dateCreated', mode.dateCreated);
          console.log('mode.dateUpdated', mode.dateUpdated);
          const update: any = {};
          if (mode.dateUpdated._delegate) {
            const ts = mode.dateUpdated._delegate;
            update.dateUpdated = new firebase.firestore.Timestamp(ts.seconds, ts.nanoseconds);
          }
          if (mode.dateUpdated.if) {
            const ts = mode.dateUpdated.if;
            update.dateUpdated = new firebase.firestore.Timestamp(ts.seconds, ts.nanoseconds);
          }
          if (mode.dateCreated._delegate) {
            const ts = mode.dateCreated._delegate;
            update.dateCreated = new firebase.firestore.Timestamp(ts.seconds, ts.nanoseconds);
          }
          if (mode.dateCreated.if) {
            const ts = mode.dateCreated.if;
            update.dateCreated = new firebase.firestore.Timestamp(ts.seconds, ts.nanoseconds);
          }
          if (Object.keys(update).length) {
            batch.update(modeDoc.ref, update);
          }
        });
        batch.commit();

        console.log('done');
      });
    },

    giveAllModesLinkIds() {
      const generateId = () => {
        return currFirestore.collection('This is just to generate IDs').doc().id;
      };

      const publicUserModeGatewaysCollection = currFirestore.collection('publicUserModeGateways');
      publicUserModeGatewaysCollection.get().then((querySnapshot) => {
        querySnapshot.forEach((gatewayDoc) => {
          publicUserModeGatewaysCollection
            .doc(gatewayDoc.data().docId)
            .collection('modes')
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((modeDoc) => {
                const batch = currFirestore.batch();
                const mode = modeDoc.data();
                const linkId = generateId().slice(0, 7);
                if (!mode.linkId) {
                  batch.update(modeDoc.ref, {
                    linkId,
                  });
                  batch.set(currFirestore.collection('shortPermalinks').doc(linkId), {
                    modeId: mode.docId,
                    userId: gatewayDoc.data().docId,
                    dateCreated: firebase.firestore.FieldValue.serverTimestamp(),
                  });
                }
                batch.commit();
                console.log('done');
              });
            });
        });
      });
    },
    assignDevicesToAllGenericMappings() {
      const genericIdToUserIdMappings = currFirestore.collection('genericIdToUserIdMappings');
      genericIdToUserIdMappings.get().then((querySnapshot) => {
        querySnapshot.forEach((mappingDoc) => {
          const mappingDocData = mappingDoc.data();
          if (!mappingDocData.interactionDeviceType) {
            mappingDoc.ref.update({
              interactionDeviceType: mappingDocData.deviceType || mappingDocData.deviceData || eInteractionDeviceTypes.blackSitchCard,
              deviceType: firebase.firestore.FieldValue.delete(),
              deviceData: firebase.firestore.FieldValue.delete(),
            });
          }
          console.log('done');
        });
      });
    },
    updateUsersBasedOnDeviceMappings() {
      const genericIdToUserIdMappings = currFirestore.collection('genericIdToUserIdMappings');
      genericIdToUserIdMappings.get().then((querySnapshot) => {
        querySnapshot.forEach((mappingDoc) => {
          const mappingDocData = mappingDoc.data();
          if (mappingDocData.userId !== 'TBD') {
            currFirestore
              .collection('users')
              .doc(mappingDocData.userId)
              .update({
                [`genericIdsBoundToThisAccount.${mappingDoc.id}`]: {
                  interactionDeviceType: mappingDocData.interactionDeviceType,
                  name: '',
                  genericId: mappingDoc.id,
                  dateActivated: Date.now(),
                },
              });
          }

          console.log('done');
        });
      });
    },
    // first: assignDevicesToAllGenericMappings(); // Get all the device data right.
    // second: updateUsersBasedOnDeviceMappings(); // Set the device data in user docs.
    onUpdateModesOfType() {
      const publicUserModeGatewaysCollection = currFirestore.collection('publicUserModeGateways');
      publicUserModeGatewaysCollection.get().then((querySnapshot) => {
        querySnapshot.forEach((gatewayDoc) => {
          publicUserModeGatewaysCollection
            .doc(gatewayDoc.data().docId)
            .collection('modes')
            .get()
            .then((querySnapshot) => {
              const batch = currFirestore.batch();
              querySnapshot.forEach((modeDoc) => {
                const mode = modeDoc.data();
                switch (mode.type) {
                  case 'text':
                    batch.update(modeDoc.ref, {
                      type: 'article',
                    });
                    break;
                }
              });
              batch.commit();
              console.log('done');
            });
        });
      });
    },
    onUpdateUserGatewayData() {
      currFirestore
        .collection('publicUserModeGateways')
        .get()
        .then((querySnapshot: any) => {
          querySnapshot.forEach((doc: any) => {
            currFirestore.collection('publicUserModeGateways').doc(doc.data().docId).update({
              // isSitchLinkActivated: true,
              // isNfcActivated: firebase.firestore.FieldValue.delete(),
              // premiumDeactivationDate: firebase.firestore.FieldValue.delete(),
            });
          });
        });
    },
    getSubs() {
      standardApiFetch(endpoints.getAllSubscriptions, {}).then((response) => {
        console.log(response.successfulResponse);
        response.successfulResponse.subscriptions.forEach((sub: any) => {
          console.log('not cancelled:');
          if (!sub.cancel_at_period_end) {
            console.log(sub);
          }
        });
      });
    },
    cancelSub() {
      standardApiFetch(endpoints.cancelSubscription, {
        subscriptionId: this.subIdToCancel,
      }).then((response) => {
        console.log(response.successfulResponse);
      });
    },
  },
});
