import { isProductionEnv } from './main';

export const standardApiFetch = (endpoint: string, body: any): Promise<SitchApiResponse> => {
  const apiUrl = isProductionEnv ? 'https://sitch-api-dot-sitch-app.appspot.com' : 'https://sitch-test-api-dot-sitch-app-test-64014.uc.r.appspot.com';
  return new Promise((resolve, reject) => {
    function rejectAndShowError(sitchApiResponse: SitchApiErrorResponse) {
      if (sitchApiResponse.errorMessage) {
        console.error(sitchApiResponse.errorMessage);
        reject(sitchApiResponse);
      }
    }

    fetch(`${apiUrl}/${endpoint}`, {
      method: 'post',
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *client
      body: JSON.stringify(body),
    })
      .then((response) => {
        response
          .json()
          .then((jsonResponse) => {
            if (jsonResponse.error || jsonResponse.errorMessage) {
              rejectAndShowError(jsonResponse);
            } else {
              resolve(jsonResponse);
            }
          })
          .catch((error: any) => {
            rejectAndShowError({ error, errorMessage: `Error getting json from the response. ${error?.message}` });
          });
      })
      .catch((error: any) => {
        rejectAndShowError({ error, errorMessage: `Error making standard api fetch. ${error?.message}` });
      });
  });
};

export const endpoints = {
  // Stripe endpoints.
  getCurrencyData: 'get-currency-data',
  getStripeAccount: 'get-stripe-account',
  getStripeSubscription: 'get-stripe-subscription',
  cancelStripeSubscription: 'cancel-stripe-subscription',
  reactivateStripeSubscription: 'reactivate-stripe-subscription',
  stripeConnectWebhook: 'stripe-connect-webhook',
  stripeWebhook: 'stripe-webhook',
  connectStripeAccount: 'connect-stripe-account',
  createStripeIntent: 'create-stripe-intent',
  createRefund: 'create-refund',
  saveOrderBreakdown: 'create-order-breakdown',
  getAllSubscriptions: 'get-all-subscriptions',
  cancelSubscription: 'cancel-subscription',
  getInvoices: 'get-invoices',
  saveCustomFieldsForPayment: 'save-custom-fields-for-payment',
  // Booking.
  bookAppointment: 'book-appointment',
  cancelBooking: 'cancel-booking',
  getBookingSlotSpacesRemaining: 'get-booking-slot-spaces-remaining',
  setReminder: 'set-reminder',
  // Sitch Client Form submissions.
  contactTracingSubmit: 'contact-tracing-submit',
  customFormSubmit: 'custom-form-submit',
  sendVerificationEmail: 'send-verification-email',
  verifyEmail: 'verify-email',
  // Permalinks.
  createCustomPermalink: 'create-custom-permalink',
  createShortPermalink: 'create-short-permalink',
  // Sitch API.
  sitchApi: 'sitch-api',
  // File Generation
  vcard: 'vcard',
  ical: 'ical',
  // Chat
  joinChat: 'join-chat',
  sendChatMessage: 'send-chat-message',
  deleteChatMessage: 'delete-chat-message',
  updateChatMessage: 'update-chat-message',
  updateChatName: 'update-chat-name',
  addWebPushTokenToChat: 'add-web-push-notification-to-chat',
  removeWebPushTokenFromChat: 'remove-web-push-notification-from-chat',
  leaveChat: 'leave-chat',
  deleteChat: 'delete-chat',
  resetChat: 'reset-chat',
  // Translations
  doTranslations: 'do-translations',
  // Misc.
  affiliateSubmit: 'affiliate-submit',
  activateSitchLink: 'activate-sitch-link',
  notificationTest: 'notification-test',
  getCountryFromIp: 'get-country-from-ip',
};