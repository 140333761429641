export default {
  email: `Email`,
  payWithCardOption: `Or pay with a credit card`,
  payWithDigitalWalletOption: `Press here to pay with your digital wallet.`,
  chooseTip: `Choose Tip`,
  payWithCard: `Pay with Card`,
  paymentSuccessful: `Payment Successful`,
  payWithPaypal: `Pay with PayPal`,
  baseAmount: `Pre Total`,
  show: 'Show',
  hide: 'Hide',
  tax: `Tax`,
  tip: `Tip`,
  total: `Total`,
  fee: `Free`,
  other: 'Other %',
  messageSentSuccessfully: 'Your message was sent successfully.',
  submissionSuccessful: 'Submission successful.',
  poweredBy: `Powered by Sitch. To create your own, sign up <a target="_blank" href="https://mysitch.app">here.</a>`,
  send: 'Send',
  pickAnOption: 'Pick an Option',
  deliveryAddress: 'Delivery Address',
  addressLine2: `Unit/Apartment/Suite/Other`,
  enterYourAddress: `Start typing`,
  additionalAddressDetails: `Additional Address Details (buzzer number, location oddities, etc.)`,
  emailForReceipt: 'Email for receipt',
  changeDeliveryAddress: 'Change Delivery Address',
  checkout: 'Checkout',
  useThisAddress: 'Use this Address',
  mustBeValidEmail: `Must be a valid email`,
  mustBeValidPhoneNumber: `Must be a valid phone number`,
  mustBeValidUrl: `Must be a valid URL`,
  isRequired: `This is required`,
  mustBeLessThan: `Must be less than {0}`,
  validationError: `{0} is invalid. {1}.`,
  makeAnotherPurchase: `Make Another Purchase`,
  accessWifi: `To join the WiFi, screenshot and scan this QR code with an AI screen reader like Google Lens, or enter the credentials below.`,
  error: 'Error',
  isOutOfDeliveryRange: `Must be within {0} km for delivery. You are {1} km away.`,
  userDoesNotExist: 'User does not exist. Recheck URL.',
  thisLinkIsNotYetActivatedTitle: 'Link not activated',
  thisLinkIsNotYetActivatedDescription:
    'This link has not yet been activated for physical QR/NFC usage. To activate it purchase a Stich Card and bind it to your account. This Sitch can still be shared via the in app QR code instead.',
  whenToBeReminded: 'When to be reminded',
  no: 'No',
  yes: 'Yes',
  okay: 'Okay',
  close: 'Close',
  menu: 'Menu',
  readTime: '{0} minute read',
  cannotCompletePayment: 'Cannot complete payment.',
  soldOutThing: 'You ordered {0}x {1}, but there is {2} left in stock.',
  noAutocomplete: `Type in an address and select an option from the dropdown`,
  name: 'Name',
  discount: 'Discount',
  promoCode: 'Promo Code',
  promoCodeMessage: 'This promo code gets you {0}% off!',
  promoCodeInvalid: 'This promo code is invalid.',
  notEnoughStockReduceQuantity: 'Not enough stock.',
  interacEmail: 'Email for Interac',
  interacPhoneNumber: 'Phone Number for Interac',
  interacPassword: 'Preferred Interac Password',
  payWithInterac: 'Pay with Interac',
  yourBank: 'Type your bank here',
  chargeAmountTooSmall: 'Charge amount is too small',
  needsToBeDelivered: 'Needs to be delivered?',
  info: 'Info',
  shopTypeInfo:
    'If your ordering from home or ordering something large that nees to be shipped, pick the "Deliver" option. If you are with the product/service provider and are just checking out, pick "No"',
  choose: `Choose {0}`,
  mustChoose: `Must choose {0} from the "{1}" section.`,
  mustChooseOrOptOut: `Must choose {0} from the "{1}" section or select the {2} option.`,
  chooseUpTo: `Choose up to {0}`,
  asManyAsYoudLike: `Choose as many as you'd like.`,
  multipleOfSingleModifier: `Repeatedly select an item to increase the quantity.`,
  modifierSoldOut: `This option is sold out.`,
  soldOut: 'Sold Out',
  canOnlySelectMaxModifiers: `Can only select {0}. Unselect another option to pick this one.`,
  quantity: 'Quantity',
  addToCart: 'Add to Cart',
  ageRequirement: 'Age Requirement',
  areYouOverYearsOld: `Are you over {0} years old? You must be at least {0} years old to buy this.`,
  tooYoungToOrder: `You are too young to buy this item`,
  IDontWantAny: `None`,
  IJustWant: `I just want {0}`,
  itemOptions: `Item Options`,
  addedToYourOrder: `{0}x {1} added to your order.`,
  cancel: 'Cancel',
  removeItem: 'Remove Item',
  removeAll: 'Remove All',
  removeOne: 'Remove One',
  yourOrder: 'Your Order',
  yourReceiptHasBeenEmailedToYou: 'Your receipt has been emailed to you.',
  addToYourCalendar: 'Add to your Calendar',
  durationBoth: '{0} hours, {1} minutes',
  durationHoursOnly: '{0} hours',
  durationMinutesOnly: '{0} minutes',
  everyDay: 'Every day',
  every: 'Every {0}',
  sunday: 'Sunday',
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  pickASlot: 'Pick a Slot',
  confirm: 'Confirm',
  availability: 'Availability',
  selectedSlot: 'Chosen Slot',
  phoneNumber: 'Phone Number',
  bookingSuccessful: 'Booking successful',
  bookingUnsuccessful: 'Could not complete the booking. That slot may have been taken very recently. Refresh and try again.',
  bookingUnsuccessfulMaxSlots: 'Could not complete the booking. Unfortunately, someone else just took the last space for this slot.',
  cancellationSuccessful: 'Booking cancellation successful',
  emailVerfied: 'Email successfully verified. Press the "Email is Verified" button on <a target="_blank" href="https://mysitch.app">mysitch.app</a> or refresh the site to begin using it.',
  cancellationUnsuccessfulNoMode: `Could not cancel the booking, try again later or contact the organization directly.`,
  cancellationUnsuccessful: `Could not cancel the booking. The booking may have already been cancelled. If not, try again later or try contacting the vendor directly via email at {0}.`,
  back: 'Back',
  id: 'ID',
  Questionnaire: 'Questionnaire',
  sendMessageCovidRisk: 'We recommend getting tested for COVID-19 as soon as possble',
  sendMessageCovidIsolation: 'Based on your responses self-isolation for 14 days is recommended.',
  mustAnswerAllQuestions: 'Must answer all the questions in the questionnaire.',
  doYouHaveSymptoms: 'Do you have any of the following symptoms?',
  symptom1: 'Fever (a temperature of 37.8 degrees celcius or higher)',
  symptom2: 'Chills',
  symptom3: 'New or worsening cough',
  symptom4: 'Barking cough, making whistling noise when breathing (croup)',
  symptom5: 'Shortness of breath (unable to breathe deeply or feeling unable to catch one’s breath)',
  symptom6: 'Sore throat (not related to seasonal allergies or other known conditions)',
  symptom7: 'Trouble/Difficulty swallowing',
  symptom8: 'Runny nose, or nasal congestion (not related to seasonal allergies or other known causes or conditions)',
  symptom9: 'Unusual headache',
  symptom10: 'Changes in taste or smell (e.g. lost sense of taste)',
  symptom11: 'Nausea or vomiting (not related to other known causes or conditions)',
  symptom12: 'Diarrhea or stomach pain (not related to other known causes or conditions)',
  symptom13: 'Unexplained fatigue (lack of energy)',
  symptom14: 'Sore muscles (long lasting or unusual)',
  symptom15: 'Pink eye (irritated, itchy or painful eye that may have crusting or discharge; conjunctivitis)',
  symptom16: 'Falling down often',
  symptom17: 'Sluggishness or lack of appetite (note these symptoms may be more relevant to infants and young children)',
  covidQuestion1: 'Have you traveled outside of the country in the last 14 days?',
  covidQuestion2: 'Have you been in close physical contact with someone who has tested positive for COVID-19 in the past 14 days?',
  covidQuestion3:
    'Have your household contacts (family members and/or roommates) or other contacts outside of school/child care presented with new COVID-19 symptoms (like a cough, fever, or difficulty breathing) in the last 14 days?',
  bookingWith: 'Booking with {0}',
  isOptional: '{0} - Optional',
  referenceId: `Order ID`,
  paymentIntentId: `Payment ID`,
  statementDescriptor: `Statement Descriptor`,
  businessEmail: `Business Email`,
  businessUrl: `Business Website`,
  businessName: `Business Name`,
  creditCardLastFour: `Last 4 digits of Payment Card`,
  chargeStatus: `Charge Status`,
  reset: 'Reset',
  stripeReceipt: `Stripe Receipt`,
  clickHere: `Press here`,
  transactionSummary: `Transaction Summary`,
  invalidTransactionId: 'Could not find the transaction record. Invalid transaction ID.',
  date: 'Date',
  noneSelected: 'None selected',
  language: 'Language',
  share: 'Share',
  message: 'Message',
  createYourOwn: 'Wanna create your own Sitch? Sign up <a target="_blank" href="https://mysitch.app">here.</a>',
  otherPosts: 'Other Posts',
  addContact: 'Add Contact',
  copyBitcoinAdress: 'Copy Bitcoin Address',
  address: 'Address',
  yourCartIsEmpty: 'Your cart is empty.',
  thankYouForYourPurchase: 'Thank you for your purchase.',
  flatDeliveryFee: 'Delivery',
  addSpecialRequest: `Add Special Request`,
  specialRequest: `Special Request`,
  enterSpecialRequestHere: 'Enter special request here.',
  setReminder: 'Set reminder?',
  byEmail: 'By email',
  byNotification: 'By notification to this device',
  notifications: 'Notifications',
  requestingNotificationsInfo: 'To perform this action you will have to enable push notification permissions.',
  reminderWasSetSuccessfully: 'Reminder set.',
  setReminderConfirm: 'Set Reminder',
  loading: 'Loading...',
  couldNotEnableNotifications: 'Could not enable notifcations. You may have notifications from sitch.app blocked. You can change this in your browsers settings.',
  minutesPrior: '{0} minutes prior at {1} on {2}',
  oneHourPrior: 'About {0} hour prior at {1} on {2}',
  hoursPrior: 'About {0} hours prior at {1} on {2}',
  changeSlot: 'Change Slot',
  deprecatedModeMessage: 'This Sitch type is no longer supported.',
  sendingPaymentDoNotClose: 'Sending payment, do not close this window.',
  costTooLow: 'The total order cost is too low to enable checkout.',
  deliveryFee: 'Delivery Fee',
  refundAmount: 'Refunded',
  emailRequired: 'Email required to proceed.',
  nameRequired: 'Name required to proceed.',
  belowMinimumChargeAmount: 'Below minimum charge amount.',
  showPaymentOptions: 'Payment Options',
  invalidEmail: 'Invalid email',
  paymentWith: 'Payment with {0}',
  thingRequired: '{0} required.',
  noModeFound: `Looks like there's nothing here. Maybe the link is invalid or the Sitch was deleted.`,
  review: 'Review',
  shortText: `Short Text`,
  longText: `Long Text`,
  number: `Number`,
  checkbox: `Checkbox`,
  multipleChoice: 'Multiple Choice',
  fullScreen: 'Fullscreen',
  shrink: 'Shrink',
  invalidCreditCardInfo: 'Invalid credit card information',
  addressRequired: 'Address required',
  payWithVenmo: 'Pay with Venmo',
  payWithCashApp: 'Pay with Cash App',
  amountToPay: 'The amount to pay is {0}',
  image: 'Image',
  // Chat specific.
  messageOptions: 'Message Options',
  editMessage: 'Edit Message',
  deleteMessage: 'Delete Message',
  thatNameIsInUse: 'That name is alrady in use',
  yourNewName: 'Your new name',
  noOneIsHereYet: 'No one is here yet.',
  chatAtMaxCapacity: 'Chat is at max capacity.',
  typeAMessage: 'Type a message',
  changeName: 'Change Name',
  getPushNotifications: 'Get Notifications',
  stopPushNotifications: 'Stop Notifications',
  thisChatWasDeleted: 'This chat was deleted.',
  hasRejoinedtheChat: '{0} has rejoined the chat.',
  hasJoinedtheChat: '{0} has joined the chat.',
  hasLeftTheChat: '{0} has left the chat',
  youHaveLeftTheChat: 'You have left the chat.',
  leave: 'Leave',
  rejoin: 'Rejoin',
  noName: 'No name',
  youAreOnline: 'You are online',
  youAreOffline: 'You are offline',
  participants: 'Participants',
  userJoinedOn: '{0} joined {1}',
  // End chat specific.
};
