



































































import Vue from 'vue';
import { currFirestore, isProductionEnv } from '../main';
import firebase from 'firebase/app';
import { eInteractionDeviceTypes } from '@/enums';

export default Vue.extend({
  name: 'app',
  data(): {
    numberOfGenericIdDocsToMake: number;
    deviceTypes: any;
    newIds: any[];
    allCodes: any[];
    headers: any[];
    firestoreLink: string;
    customerName: string;
    deviceType: eInteractionDeviceTypes;
  } {
    return {
      numberOfGenericIdDocsToMake: 0,
      deviceTypes: [
        { text: 'Black Sitch Card', value: eInteractionDeviceTypes.blackSitchCard },
        { text: 'Black Sitch Band', value: eInteractionDeviceTypes.blackSitchBand },
        { text: 'Black Sitch Dot', value: eInteractionDeviceTypes.blackSitchDot },
        { text: 'Black Unisex Shirt', value: eInteractionDeviceTypes.blackUnisexShirt },
        { text: 'Black Womens Shirt', value: eInteractionDeviceTypes.blackWomensShirt },
        { text: 'White Custom Logo Sitch Card', value: eInteractionDeviceTypes.whiteCustomLogoSitchCard },
        { text: 'Tapeeo', value: eInteractionDeviceTypes.tapeeo },
      ],
      newIds: [],
      allCodes: [],
      headers: [
        {
          text: 'Created',
          value: 'dateCreated',
          sortable: true,
        },
        {
          text: 'Document ID',
          value: 'docId',
          sortable: true,
        },
        {
          text: 'Name',
          value: 'name',
          sortable: true,
        },
        {
          text: 'User ID',
          value: 'userId',
          sortable: true,
        },
        {
          text: 'Type',
          value: 'interactionDeviceType',
          sortable: true,
        },
        {
          text: 'NFC link',
          value: 'docId',
          sortable: true,
        },
      ],
      firestoreLink: isProductionEnv
        ? 'https://console.firebase.google.com/u/0/project/sitch-app/firestore/data/~2FgenericIdToUserIdMappings~2F0tshSrl3cu8pevyrtEmO'
        : 'https://console.firebase.google.com/u/0/project/sitch-app-test-64014/firestore/data/~2FgenericIdToUserIdMappings~2F0tshSrl3cu8pevyrtEmO',
      customerName: '',
      deviceType: eInteractionDeviceTypes.blackSitchCard,
    };
  },
  methods: {
    getAllCodes() {
      const allCodes: any[] = [];
      const genericIdsCollection = currFirestore.collection('genericIdToUserIdMappings');
      const promises: any[] = [];

      promises.push(
        genericIdsCollection.get().then((querySnapshot) => {
          querySnapshot.forEach((codeDoc) => {
            const codeData = codeDoc.data() as GenericIdData;
            allCodes.push({
              ...codeData,
              dateCreated: codeData.dateCreated.seconds,
              docId: codeDoc.id,
            });
          });
        })
      );

      Promise.all(promises).then(() => {
        this.allCodes = allCodes.sort((a, b) => b.dateCreated - a.dateCreated);
      });
    },
    onGenerateGenericIds() {
      if (!this.customerName && this.deviceType !== eInteractionDeviceTypes.tapeeo) {
        alert('Missing customer name.');
        return;
      }
      const batch = currFirestore.batch();
      const newIds: any[] = [];

      for (let i = 0; i < this.numberOfGenericIdDocsToMake; i++) {
        const currDoc = currFirestore.collection('genericIdToUserIdMappings').doc();
        const newDeviceEntry: any = {
          userId: 'TBD',
          interactionDeviceType: this.deviceType,
          name: this.customerName,
          dateCreated: firebase.firestore.FieldValue.serverTimestamp(),
        };
        if (this.deviceType === eInteractionDeviceTypes.tapeeo) {
          newDeviceEntry.affiliate = 'tapeeo';
        }
        newIds.push({
          ...newDeviceEntry,
          docId: currDoc.id,
          dateCreated: newDeviceEntry.dateCreated.seconds,
        });
        batch.set(currDoc, newDeviceEntry);
      }
      batch
        .commit()
        .then(() => {
          this.newIds = newIds;
        })
        .catch((err) => {
          alert(err);
        });
    },
  },
});
