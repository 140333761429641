
































import Vue from 'vue';
import { currFirestore } from '../main';
import firebase from 'firebase/app';

export default Vue.extend({
  name: 'app',
  data(): {
    affiliates: any[];
    name: string;
    userId: string;
    promoCode: string;
    customerName: string;
    headers: any[];
  } {
    return {
      affiliates: [],
      name: '',
      userId: '',
      promoCode: '',
      customerName: '',
      headers: [
        {
          text: 'Name',
          value: 'name',
          sortable: true,
        },
        {
          text: 'User Id',
          value: 'userId',
          sortable: true,
        },
        {
          text: 'Promo Code',
          value: 'promoCode',
          sortable: true,
        },
        {
          text: 'Email',
          value: 'email',
          sortable: true,
        },
      ],
    };
  },
  methods: {
    updateAffiliateList() {
      currFirestore
        .collection('affiliates')
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((affiliateDoc) => {
            this.affiliates.push(affiliateDoc.data());
          });
        });
    },
    addAffiliate() {
      if (!this.name || !this.userId || !this.promoCode || !this.customerName) {
        alert('Missing data.');
        return;
      }

      const currDoc = currFirestore.collection('affiliates').doc(this.userId);
      const newAffiliateEntry = {
        name: this.name,
        userId: this.userId,
        promoCode: this.promoCode,
        customerName: this.customerName,
        dateCreated: firebase.firestore.FieldValue.serverTimestamp(),
      };

      currDoc
        .set(newAffiliateEntry)
        .then(() => {
          this.affiliates.push({
            docId: currDoc.id,
            ...newAffiliateEntry,
          });
        })
        .catch((err) => {
          alert(err);
        });
    },
  },
});
