export const productionFirebaseCredentials = {
  apiKey: 'AIzaSyCuYq1i41HFsD_YVc1rsJQ1CffSng7qzAQ',
  authDomain: 'sitch-app.firebaseapp.com',
  databaseURL: 'https://sitch-app.firebaseio.com',
  projectId: 'sitch-app',
  storageBucket: 'sitch-app.appspot.com',
  messagingSenderId: '462308206439',
  appId: '1:462308206439:web:284bad5282fe5258f371c2',
  measurementId: 'G-VQ0WY5R0MG',
};
export const stagingFirebaseCredentials = {
  apiKey: 'AIzaSyB-eIqKasstUGrtZTYRx70oXVObbt1qvRw',
  authDomain: 'sitch-app-test-64014.firebaseapp.com',
  databaseURL: 'https://sitch-app-test-64014.firebaseio.com',
  projectId: 'sitch-app-test-64014',
  storageBucket: 'sitch-app-test-64014.appspot.com',
  messagingSenderId: '125426563523',
  appId: '1:125426563523:web:2be0f85b452e3aa74d6576',
  measurementId: 'G-E2VSYLLVYV',
};
