



































































































import Vue from 'vue';
import firebase from 'firebase/app';
import 'firebase/auth';
import { isProductionEnv, signIn } from './main';

export default Vue.extend({
  name: 'App',
  data(): {
    email: string;
    pass: string;
    drawer: boolean;
    isProductionEnv: boolean;
  } {
    return {
      email: 'admin@sitch.app',
      pass: '',
      drawer: false,
      isProductionEnv: isProductionEnv,
    };
  },
  mounted() {
    document.addEventListener('keypress', (event) => {
      if (!this.$store.state.isLoggedIn && event.key === 'Enter') {
        this.onSignIn();
      }
    });
  },
  methods: {
    onSignIn() {
      signIn(this.email, this.pass);
    },
    signOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$store.commit('isLoggedIn', false);
        })
        .catch((error) => {
          console.log('Could not sign out. ' + (error.message || error));
        });
    },
    isOnPage(path: string) {
      const currPath = this.$router.mode === 'hash' ? window.location.hash : window.location.pathname;
      return currPath === path;
    },
  },
});
